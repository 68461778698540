<script setup>
import { mapErrors } from "@/utilities";
import { useForm } from "@inertiajs/vue3";
import { computed, inject, ref, watch } from "vue";

import Button from "@/Components/Button/Button.vue";
import TinyRating from "@/Components/Flexer/Partials/TinyRating.vue";
import Rating from "@/Components/Input/Rating.vue";
import ApproveHoursFormManualInputLog from "@/Pages/Location/ApproveHours/Partials/ApproveHoursFormManualInputLog.vue";
import ApproveHoursFormScanLog from "@/Pages/Location/ApproveHours/Partials/ApproveHoursFormScanLog.vue";
import ApproveHoursFormTimeForm from "@/Pages/Location/ApproveHours/Partials/ApproveHoursFormTimeForm.vue";
import ModalNoShow from "@/Pages/Location/ApproveHours/Partials/ModalNoShow.vue";

const dayjs = inject("dayjs");
const route = inject("route");

const props = defineProps({
  shift: Object,
  showing: Boolean,
  hideNextButton: Boolean
});

const emits = defineEmits(["nextRowClicked", "markedAsNoShow"]);

const showNoShowModal = ref(false);

const openNoShowModal = () => {
  showNoShowModal.value = true;
};

const form = useForm({
  shift_id: props.shift.id,
  start: dayjs(props.shift.start_by_flexer).format("YYYY-MM-DD HH:mm"),
  end: dayjs(props.shift.end_by_flexer).format("YYYY-MM-DD HH:mm"),
  break: props.shift.break_by_flexer,
  rating: null,
  remark: "",
  review: ""
});

let originalStartTime = computed(() =>
  dayjs(props.shift.start_by_flexer).format("YYYY-MM-DD HH:mm")
);
let originalEndTime = computed(() =>
  dayjs(props.shift.end_by_flexer).format("YYYY-MM-DD HH:mm")
);
let originalBreak = computed(() => String(props.shift.break_by_flexer));

watch(
  () => props.shift,
  () => {
    form.shift_id = props.shift.id;
    form.start = dayjs(props.shift.start).format("YYYY-MM-DD HH:mm");
    form.end = dayjs(props.shift.end).format("YYYY-MM-DD HH:mm");
    form.break = props.shift.break;
    form.rating = null;
    form.remark = "";
    form.review = "";
  }
);

const timeIsDirty = computed(() => {
  return (
    originalStartTime.value != form.start ||
    originalEndTime.value != form.end ||
    originalBreak.value != form.break
  );
});

const sendForm = (andNext = false, preserveState = false) => {
  const url = timeIsDirty.value
    ? route("location.amended-shifts.store")
    : route("location.accredited-shifts.store");

  form
    .transform(data => ({
      ...data,
      start: start.value.unix(),
      end: end.value.unix()
    }))
    .post(url, {
      preserveState: preserveState ? true : false,
      preserveScroll: true,
      onSuccess: () => (andNext ? emits("nextRowClicked") : null),
      onError: errors => (form.errors = mapErrors(errors))
    });
};

const duration = computed(() => {
  return (end.value.diff(start.value, "second") - form.break) / 3600;
});

const start = computed(() => dayjs(form.start));
const end = computed(() => dayjs(form.end));

const isCompeted = props.shift.status === "completed";
const isMarkedAsNoShow = props.shift.status === "no_show";
const isMarkedAsAccredited = props.shift.status === "accredited";
const passedStart = computed(() => dayjs(props.shift.start).isBefore(dayjs()));

const remark = computed(() => props.shift.review?.review);
</script>

<template>
  <div class="grid">
    <div class="mt-4 grid gap-8 text-blue md:grid-cols-3">
      <ApproveHoursFormTimeForm :shift="shift" :form="form" />
      <div>
        <div class="text-base">
          <div class="flex gap-8">
            <div v-if="shift.duration_by_flexer">
              <p class="mb-3">{{ $t('Calculated') }}</p>
              <span class="font-bold">{{ shift.duration_by_flexer }}</span>
            </div>
            <div v-if="shift.break_duration_by_flexer">
              <p class="mb-3">{{ $t('Paused') }}</p>
              <span class="font-bold">{{ shift.break_duration_by_flexer }}</span>
            </div>
          </div>
          <ApproveHoursFormScanLog
            v-if="typeof shift.scan_log != 'undefined' && shift.scan_log.length > 0"
            :scan-log="shift.scan_log"
          />
          <ApproveHoursFormManualInputLog v-else :shift="shift" />
        </div>
      </div>

      <div>
        <div v-if="!isCompeted" class="mb-4">
          <TinyRating :big-stars="true" :value="shift.reviews?.[0]?.rating" :label="$t('Rating')" />
        </div>
        <div v-else>
          <Rating
            v-model:value="form.rating"
            :label="$t('Rating')"
            :errors="form.errors.rating"
            with-text
          />
        </div>

        <template v-if="shift.start_by_flexer">
          <div v-if="!isCompeted">
            <FormKit
              v-model="remark"
              :label="$t('Remark')"
              type="textarea"
              name="review"
              :value="remark"
              :classes="{
                                input: 'bg-gray-medium',
                            }"
              readonly="readonly"
            />
          </div>
          <div v-else>
            <FormKit
              v-model="form.review"
              :label="$t('Remark')"
              type="textarea"
              name="review"
              :value="form.review"
              :errors="form.errors.review"
            />
          </div>
        </template>
      </div>
    </div>

    <div v-if="isCompeted || passedStart">
      <div class="flex justify-end gap-4">
        <div
          v-if="isCompeted && duration < 3"
          class="flex flex-1 items-center text-red-600"
        >{{ $t('By law, the fl@xr will be rewarded a minimum of 3 hours') }}</div>
        <Button
          v-if="!isMarkedAsNoShow && !isMarkedAsAccredited"
          danger
          class="inline-flex w-fit"
          @click="openNoShowModal()"
        >
          <div class="flex-center gap-2 font-bold">
            <span>{{ $t('No show') }}</span>
          </div>
        </Button>

        <div
          v-if="isCompeted"
          class="flex justify-end gap-4"
          :class="{ 'pointer-events-none opacity-50': !form.rating }"
        >
          <template v-if="timeIsDirty">
            <Button :warning="timeIsDirty" orange class="inline-flex w-fit" @click="sendForm">
              <div class="flex-center gap-2 font-bold">
                <span>{{ $t('Amend') }}</span>
              </div>
            </Button>

            <Button
              orange
              class="inline-flex w-fit"
              :class="{ hidden: hideNextButton }"
              @click="sendForm(true, true)"
            >
              <div class="flex-center gap-2 font-bold">
                <span>{{ $t('Amend & next') }}</span>
              </div>
            </Button>
          </template>

          <template v-else>
            <Button green class="inline-flex w-fit" @click="sendForm">
              <div class="flex-center gap-2 font-bold">
                <span>{{ $t('Approve') }}</span>
              </div>
            </Button>

            <Button
              green
              class="inline-flex w-fit"
              :class="{ hidden: hideNextButton }"
              @click="sendForm(true, true)"
            >
              <div class="flex-center gap-2 font-bold">
                <span>{{ $t('Approve & next') }}</span>
              </div>
            </Button>
          </template>
        </div>
      </div>
    </div>
  </div>
  <ModalNoShow
    v-model:showing="showNoShowModal"
    :shift="shift"
    @marked-as-no-show="() => emits('markedAsNoShow')"
  />
</template>
